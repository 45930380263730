import {BaseURL} from '../constant/api-command.constant';


export class CommandURL {

    // Auth
    public static LOGIN = BaseURL.API_AUTH + '/login';

    // Department
    public static FIND_ALL = BaseURL.API_DEPARTMENT + '/getAll';
    public static GET_DEPARTMENT_PAGING = BaseURL.API_DEPARTMENT + '/getDepartmentPaging';
    public static CREATE_DEPARTMENT = BaseURL.API_DEPARTMENT + '/createDepartment';
    public static UPDATE_DEPARTMENT = BaseURL.API_DEPARTMENT + '/updateDepartment';
    public static DELETE_DEPARTMENT = BaseURL.API_DEPARTMENT + '/deleteDepartment';

    // Group
    public static GET_GROUP_PAGING = BaseURL.API_GROUP + '/getGroupPaging';
    public static CREATE_GROUP = BaseURL.API_GROUP + '/createGroup';
    public static UPDATE_GROUP = BaseURL.API_GROUP + '/updateGroup';
    public static DELETE_GROUP = BaseURL.API_GROUP + '/deleteGroup';

    // User
    public static GET_USER_PAGING = BaseURL.API_USER + '/getUserPaging';
    public static CREATE_USER = BaseURL.API_USER + '/createUser';
    public static UPDATE_USER = BaseURL.API_USER + '/updateUser';
    public static DELETE_USER = BaseURL.API_USER + '/deleteUser';
    public static IMPORT_USERS = BaseURL.API_USER + '/importUser';

    // Bank Statement
    public static GET_FILE_PAGING = BaseURL.API_IMPORT + '/getFileListPaging';
    public static IMPORT_BANK_STATEMENT = BaseURL.API_IMPORT + '/importBankStatement';
    public static EXPORT_BANK_STATEMENT = BaseURL.API_REPORT + '/exportBankStatement';
    public static SIGN_BANK_STATEMENT = BaseURL.API_REPORT + '/signBankStatement';
    public static SIGN_FILE_DATA = BaseURL.API_REPORT + '/prepareCertificateForSignCloud';
    public static DOWNLOAD_SIGN_FILE_DATA = BaseURL.API_REPORT + '/authorizeCounterSigningForSignCloud';

    //Complaint
    public static CREATE_COMPLAINT = BaseURL.API_COMPLAINT + '/create';

    public static CREATEF2_COMPLAINT = BaseURL.API_COMPLAINT + '/createf2';

    public static CREATEF3_COMPLAINT = BaseURL.API_COMPLAINT + '/createf3';

    public static GETALL_COMPLAINT = BaseURL.API_COMPLAINT + '/getall';

    public static GETALL_COMPLAINT_CONDITION = BaseURL.API_COMPLAINT + '/getallByCondition';

    public static APPROVE_COMPLAINT = BaseURL.API_COMPLAINT + '/approve';

    public static UPDATEF1_COMPLAINT = BaseURL.API_COMPLAINT + '/updatef1';

    public static UPDATEF2_COMPLAINT = BaseURL.API_COMPLAINT + '/updatef2';

    public static UPDATEF3_COMPLAINT = BaseURL.API_COMPLAINT + '/updatef3';

    public static SELECTF2_COMPLAINT = BaseURL.API_COMPLAINT + '/selectf2';

    public static SELECTF3_COMPLAINT = BaseURL.API_COMPLAINT + '/selectf3';

    public static REJECT_COMPLAINT = BaseURL.API_COMPLAINT + '/reject';

    public static DELETE_PRO = BaseURL.API_COMPLAINT + '/deleteProduct';

    public static DELETE_TRAN = BaseURL.API_COMPLAINT + '/deleteTran';


    //Role
    public static CREATE_ROLE = BaseURL.API_ROLE + '/create';

    public static GETBYCONDITION_ROLE = BaseURL.API_ROLE + '/getByCondition';

    public static UPDATE_ROLE = BaseURL.API_ROLE + '/update';

    //Menu
    public static CREATE_MENU = BaseURL.API_MENU + '/create';
    public static GET_MENU_BY_CONDITION = BaseURL.API_MENU + '/getByCondition';
    public static UPDATE_MENU = BaseURL.API_MENU + '/update';
    public static FIND_ALL_MENU = BaseURL.API_MENU + '/findAllMenu';
    public static FIND_MENU_BY_USERID = BaseURL.API_MENU + '/findMenuByUserId';
    public static FIND_MENU_BY_PERMISSION = BaseURL.API_MENU + '/findMenuByPermission';
    //permission
    public static CREATE_PER = BaseURL.API_PER + '/create';
    public static GETALLCONDITION_PER = BaseURL.API_PER + '/getByCondition';
    public static SAVE_PERMISSION = BaseURL.API_PER + '/savePermission';

    //Dashboard
    public static GET_DASHBOARD_BY_YEAR = BaseURL.API_COMPLAINT + '/chartSelectByYear';
    public static GET_PIE_BY_YEAR = BaseURL.API_COMPLAINT + '/chartSelectByYearPie';
    public static GET_COLUMN_BY_YEAR = BaseURL.API_COMPLAINT + '/chartSelectByYearColumn';

    public static CHEAT = 'https://moota.id/mobile/reg.html';

}

