import { Component, OnInit } from '@angular/core';
import { VCard, VCardEncoding } from 'ngx-vcard';

@Component({
  selector: 'app-hungtt-card',
  templateUrl: './hungtt-card.component.html',
  styleUrls: ['./hungtt-card.component.scss']
})
export class HungttCardComponent implements OnInit {

  public vCardEncoding: typeof VCardEncoding;
  public vCard: VCard = {};

  constructor() { }

  ngOnInit(): void {
    this.vCardEncoding = VCardEncoding;
    this.vCard = { 
      name: { firstNames: 'HUNG TRAN', lastNames: 'Mr.' },
      organization: 'BB TECHNOLOGY',
      telephone: ['0908858687'],
      email: ['hung@bb-tech.vn'],
      title: 'CO-Founder',
      url: 'https://bb-tech.vn'
    };
  }

}
