import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LangdingComponent} from './langding/langding.component';
import { CompanyPresentComponent } from './company-present/company-present.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductDetailComponent } from './product-list/product-detail/product-detail.component';
import { ServiceProviderComponent } from './service-provider/service-provider.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { AboutCompanyComponent } from './about-company/about-company.component';
import { DinvCardComponent } from './dinv-card/dinv-card.component';
import { DuongptCardComponent } from './duongpt-card/duongpt-card.component';
import { HungttCardComponent } from './hungtt-card/hungtt-card.component';
import { PhuchdCardComponent } from './phuchd-card/phuchd-card.component';
import { TrangntnCardComponent } from './trangntn-card/trangntn-card.component';

const routes: Routes = [
    {path: 'account', redirectTo: 'langding', pathMatch: 'full'},
    {path: 'langding', component: LangdingComponent},
    {path: 'company', component: CompanyPresentComponent},
    {path: 'e-card/delivery', component: DinvCardComponent},
    {path: 'e-card/cyber', component: DuongptCardComponent},
    {path: 'e-card/ceo', component: HungttCardComponent},
    {path: 'e-card/deputy', component: PhuchdCardComponent},
    {path: 'e-card/sale', component: TrangntnCardComponent},
    {path: 'product', component: ProductListComponent},
    {path: 'product-detail', component: ProductDetailComponent},
    {path: 'services', component: ServiceProviderComponent},
    {path: 'customer', component: CustomerListComponent},
    {path: 'about-us', component: AboutCompanyComponent},
    {path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountRoutingModule {
}
