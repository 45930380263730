import { Component, HostListener, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../auth/login/login.component';
import { RegisterUseComponent } from '../register-use/register-use.component';
declare var $;

@Component({
    selector: 'app-company-present',
    templateUrl: './company-present.component.html',
    styleUrls: ['./company-present.component.scss']
})
export class CompanyPresentComponent implements OnInit {
    @HostListener('window:scroll', ['$event']) // for window scroll events
    onScroll(event) {
        const element = document.querySelector('#topnav') as HTMLElement;
        const element1 = document.querySelector('#contentTop1') as HTMLElement;
        if (window.pageYOffset > (element1.clientHeight) - 100 && window.pageYOffset > 0) {
            element.classList.add('scrolled-nav');
        } else {
            console.log('remove');
            element.classList.remove('scrolled-nav');
        }
    }

    constructor(
        private modalService: NgbModal,
    ) {}

    openLogin() {
        const modalRef = this.modalService.open(LoginComponent, {
            centered: true,
            backdrop: true,
            scrollable: true
        });
        // if (item) {
        //     modalRef.componentInstance.item = item;
        // }
        // modalRef.componentInstance.title = item ? 'Edit' : 'Create';
        modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
            this.modalService.dismissAll();
        });
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        // document.body.classList.add('authentication-bg');
    }

    ngOnDestroy() {
        // document.body.style.backgroundImage = 'None';
        // document.body.style.backgroundColor = 'transparent';
    }

    openModalRegistry() {
        const modalRef = this.modalService.open(RegisterUseComponent, {
            centered: true,
            backdrop: 'static',
            scrollable: true,
            size: 'lg'
        });
        modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
            this.modalService.dismissAll();
        });
    }

}
