import { environment } from 'src/environments/environment';


export const BaseURL = {

    API_AUTH: environment.PORTAL_SERVICE + 'auth',
    API_DEPARTMENT: environment.PORTAL_SERVICE + 'department',
    API_GROUP: environment.PORTAL_SERVICE + 'group',
    API_USER: environment.PORTAL_SERVICE + 'user',
    API_IMPORT: environment.PORTAL_SERVICE + 'import',
    API_REPORT: environment.PORTAL_SERVICE + 'report',

    API_COMPLAINT: environment.PORTAL_SERVICE + 'complaint',

    API_ROLE: environment.PORTAL_SERVICE + 'role',

    API_MENU: environment.PORTAL_SERVICE + 'menu',

    API_PER: environment.PORTAL_SERVICE + 'per',

};
