import { Component, HostListener, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../../auth/login/login.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {
  
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
      const element = document.querySelector('#topnav') as HTMLElement;
      const element1 = document.querySelector('#contentTop1') as HTMLElement;
      if (window.pageYOffset > (element1.clientHeight) - 100 && window.pageYOffset > 0) {
          element.classList.add('scrolled-nav');
      } else {
          console.log('remove');
          element.classList.remove('scrolled-nav');
      }
  }
  productType = 1;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    ) { }

  ngOnInit(): void {
  }

  openLogin() {
    const modalRef = this.modalService.open(LoginComponent, {
        centered: true,
        backdrop: true,
        scrollable: true
    });
    // if (item) {
    //     modalRef.componentInstance.item = item;
    // }
    // modalRef.componentInstance.title = item ? 'Edit' : 'Create';
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
        this.modalService.dismissAll();
    });
  }

  changeProductType(type: number) {
    this.router.navigate(['/account/product'], {
      state: {
        productType: type,
      }
    });
  }

}
