import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UserProfileService } from 'src/app/core/services/auth/user.service';

@Component({
  selector: 'app-register-use',
  templateUrl: './register-use.component.html',
  styleUrls: ['./register-use.component.scss']
})
export class RegisterUseComponent implements OnInit {

  infoForm: FormGroup;
  loading = false;
  submitted = false;
  listSize = [
    {name: 'Số lượng nhân viên < 100', value: 'N100'},
    {name: 'Số lượng nhân viên từ 100 -> 500', value: 'N500'},
    {name: 'Số lượng nhân viên từ 500 -> 1000', value: 'N1000'},
    {name: 'Số lượng nhân viên > 1000', value: 'L1000'}
  ];
  listGenders = [
    {name: 'Nam', value: 'M'},
    {name: 'Nữ', value: 'F'}
  ]

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private userProfileService: UserProfileService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.infoForm = this.formBuilder.group({
      companyName: ['', [Validators.required]],
      taxCode: ['', [Validators.required]],
      account: [''],
      address: ['', [Validators.required]],
      phone: ['', Validators.required],
      tax: [''],
      numberNV: ['N100'],
      contactName: ['', Validators.required],
      gender: ['M'],
      level: ['', Validators.required],
      email: ['', Validators.required],
      mobilePhone: ['', Validators.required],
    });
  }

  get f() { return this.infoForm.controls; }

  onSubmit() {
    this.submitted = true;
    if(this.infoForm.invalid) {
      this.toastr.info('Vui lòng nhập đầy đủ thông tin', 'Thông báo');
      return;
    }
    const json = this.infoForm.value;
    this.loading = true;
    this.userProfileService.registerUse(json).subscribe(data => {
      this.loading = false;
      if(data?.errorCode === '0') {
        this.toastr.success(data?.errorDesc, 'Thông báo');
        this.modalService.dismissAll();
        this.initForm();
      } else {
        this.toastr.error(data?.errorDesc, 'Lỗi');
      }
    }, _error => {this.loading = false;});
  }

  closePopup() {
    this.modalService.dismissAll();
  }

}
