import { Component, OnInit } from '@angular/core';
import { VCard, VCardEncoding } from 'ngx-vcard';

@Component({
  selector: 'app-trangntn-card',
  templateUrl: './trangntn-card.component.html',
  styleUrls: ['./trangntn-card.component.scss']
})
export class TrangntnCardComponent implements OnInit {
  public vCardEncoding: typeof VCardEncoding;
  public vCard: VCard = {};

  constructor() { }

  ngOnInit(): void {
    this.vCardEncoding = VCardEncoding;
    this.vCard = { 
      name: { firstNames: 'TRANG NGO', lastNames: 'Ms.' },
      organization: 'BB TECHNOLOGY',
      telephone: ['0775051168'],
      email: ['trang.ngo@bb-tech.vn'],
      title: 'Sales Representative',
      url: 'https://bb-tech.vn'
    };
  }

}
