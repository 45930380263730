import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  productType = 1;
  constructor(private router: Router,) { }

  ngOnInit() {
  }

  changeProductType(type: number) {
    this.router.navigate(['/account/product'], {
      state: {
        productType: type,
      }
    });
  }
}
