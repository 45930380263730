import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import Swiper from 'swiper';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoginComponent} from '../auth/login/login.component';
import { RegisterUseComponent } from '../register-use/register-use.component';

declare var $;

@Component({
    selector: 'app-langding',
    templateUrl: './langding.component.html',
    styleUrls: ['./langding.component.scss']
})
export class LangdingComponent implements OnInit, AfterViewInit, OnDestroy {
    products = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    constructor(
        private modalService: NgbModal,
    ) {
    }

    @HostListener('window:scroll', ['$event']) // for window scroll events
    onScroll(event) {
        const element = document.querySelector('#topnav') as HTMLElement;
        const element1 = document.querySelector('#contentTop1') as HTMLElement;

        if (window.pageYOffset > (element1.clientHeight) - 100) {
            element.classList.add('scrolled-nav');
        } else {
            element.classList.remove('scrolled-nav');
        }
    }

    openLogin() {
        const modalRef = this.modalService.open(LoginComponent, {
            centered: true,
            backdrop: true,
            scrollable: true
        });
        // if (item) {
        //     modalRef.componentInstance.item = item;
        // }
        // modalRef.componentInstance.title = item ? 'Edit' : 'Create';
        modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
        this.modalService.dismissAll();
        });
    }

    ngOnInit(): void {
        let parallaxSlider;
        const parallaxSliderOptions = {
            speed: 1000,
            autoplay: true,
            parallax: true,
            loop: true,
            grabCursor: true,
            centeredSlides: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            on: {
                init() {
                    const swiper = this;
                    // tslint:disable-next-line:prefer-for-of
                    for (let i = 0; i < swiper.slides.length; i++) {
                        (swiper.slides[i] as HTMLDivElement)
                            .querySelector('.img-container')
                            ?.setAttribute('data-swiper-parallax', `${0.75 * swiper.width}`);
                        (swiper.slides[i] as HTMLDivElement)
                            .querySelector('.img-container')
                            ?.setAttribute('data-swiper-parallax-opacity', `${1}`);

                        (swiper.slides[i] as HTMLDivElement)
                            .querySelector('.title')
                            ?.setAttribute('data-swiper-parallax', `${0.65 * swiper.width}`);

                        (swiper.slides[i] as HTMLDivElement)
                            .querySelector('.description')
                            ?.setAttribute('data-swiper-parallax', `${0.5 * swiper.width}`);
                    }
                },
                resize() {
                    this.update();
                }
            },
            navigation: {
                nextEl: '.parallax-slider .next-ctrl',
                prevEl: '.parallax-slider .prev-ctrl'
            }
        };

        parallaxSlider = new Swiper('.parallax-slider', parallaxSliderOptions);
        window.onresize = () => {
            parallaxSlider.destroy();
            parallaxSlider = new Swiper('.parallax-slider', parallaxSliderOptions);
        };

        $('.js-section-scroll').on('click', function (e) {
            e.preventDefault();
            const section = $(this).attr('href').substr($(this).attr('href').indexOf('#'));
            const $section = $(section);

            $('html, body').animate({
                scrollTop: ($section.offset().top - 100) + 'px'
            }, 600);
        });

    }

    ngAfterViewInit() {
        // document.body.classList.add('authentication-bg');
    }

    ngOnDestroy() {
        // document.body.style.backgroundImage = 'None';
        // document.body.style.backgroundColor = 'transparent';
    }

    openModalRegistry() {
        const modalRef = this.modalService.open(RegisterUseComponent, {
            centered: true,
            backdrop: 'static',
            scrollable: true,
            size: 'lg'
        });
        modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
            this.modalService.dismissAll();
        });
    }
}
