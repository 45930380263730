import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';
import { AuthModule } from './auth/auth.module';
import {LangdingComponent} from './langding/langding.component';
import {LayoutsModule} from '../layouts/layouts.module';
import { RegisterUseComponent } from './register-use/register-use.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UIModule } from '../shared/ui/ui.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { CompanyPresentComponent } from './company-present/company-present.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductDetailComponent } from './product-list/product-detail/product-detail.component';
import { ServiceProviderComponent } from './service-provider/service-provider.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { AboutCompanyComponent } from './about-company/about-company.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DinvCardComponent } from './dinv-card/dinv-card.component';
import { HungttCardComponent } from './hungtt-card/hungtt-card.component';
import { DuongptCardComponent } from './duongpt-card/duongpt-card.component';
import { PhuchdCardComponent } from './phuchd-card/phuchd-card.component';
import { TrangntnCardComponent } from './trangntn-card/trangntn-card.component';
import { NgxVcardModule } from "ngx-vcard";

@NgModule({
  declarations: [LangdingComponent, RegisterUseComponent, CompanyPresentComponent, ProductListComponent, ProductDetailComponent, ServiceProviderComponent, CustomerListComponent, AboutCompanyComponent, DinvCardComponent, HungttCardComponent, DuongptCardComponent, PhuchdCardComponent, TrangntnCardComponent],
    imports: [
        CommonModule,
        AccountRoutingModule,
        ReactiveFormsModule,
        AuthModule,
        UIModule,
        LayoutsModule,
        NgSelectModule,
        NgbModule,
        NgxVcardModule
    ]
})
export class AccountModule { }
