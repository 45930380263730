import { Component, HostListener, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../auth/login/login.component';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
      const element = document.querySelector('#topnav') as HTMLElement;
      const element1 = document.querySelector('#contentTop1') as HTMLElement;
      if (window.pageYOffset > (element1.clientHeight) - 100 && window.pageYOffset > 0) {
          element.classList.add('scrolled-nav');
      } else {
          console.log('remove');
          element.classList.remove('scrolled-nav');
      }
  }
  productType = 1;
  state$: Observable<any>;
  ngUnsubscribe: Subject<void> = new Subject<void>();
  
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
  ) {
    this.state$ = this.route.paramMap
            .pipe(map(() => window.history.state));
  }

  ngOnInit(): void {
    this.state$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(state => {
      if(state?.productType) {
        this.productType = state?.productType;
      }
    });
  }

  openLogin() {
    const modalRef = this.modalService.open(LoginComponent, {
        centered: true,
        backdrop: true,
        scrollable: true
    });
    // if (item) {
    //     modalRef.componentInstance.item = item;
    // }
    // modalRef.componentInstance.title = item ? 'Edit' : 'Create';
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
        this.modalService.dismissAll();
    });
  }

  changeProductType(type: number) {
    this.productType = type;
  }

}
