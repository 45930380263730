import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {gup} from 'src/app/core/utils/format.util';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../core/services/auth/auth.service';
import {ShareDataService} from 'src/app/share-data.service';
import {CategoryService} from '../../core/services/module/category.service';
import {User} from '../../core/models/auth.models';

declare var $;

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

    notificationItems: Array<{}>;
    selectedLanguage = 'vi';

    openMobileMenu: boolean;

    @Output() settingsButtonClicked = new EventEmitter();
    @Output() mobileMenuButtonClicked = new EventEmitter();
    filePath = 'assets/images/users/avatar_default.png';

    menuList: any[];
    user: User;

    constructor(
        private router: Router,
        private authService: AuthenticationService,
        public translate: TranslateService,
        private shareDataService: ShareDataService,
        private categoryService: CategoryService,
    ) {
        this.user = this.authService.currentUser();
    }

    ngOnInit() {
        let lang = gup('lang');
        if (lang && ['en', 'vi'].some(elem => elem === lang)) {
            this.selectedLanguage = lang;
            localStorage.setItem('language', lang);
        } else {
            lang = localStorage.getItem('language');
            this.selectedLanguage = lang ? lang : 'vi';
        }
        if (this.authService.user) {
            this.filePath = (this.authService.user.avatarUrl ? this.authService.user.avatarUrl : 'assets/images/users/avatar_default.png');
        }
        this.translate.use(this.selectedLanguage ? this.selectedLanguage : 'vi');
        this._fetchNotifications();
        this.openMobileMenu = false;

        // $(document).foundation();
        // mobile menu toggle
        $('.menu_toggle').click(function (e) {
            $('.menu_toggle .menu.button').toggleClass('on');
            $('.svg_menu_toggle').toggleClass('open');
            $('.mobile_nav').toggleClass('open');
        });

        this.getListMenu();
    }

    getListMenu() {
        this.menuList = [];
        this.categoryService.findMenuByPermission(this.authService.currentUser().userCode).subscribe(res => {
            this.menuList = res.data.filter(x => x.parentId !== 'f4b16afe-0b3c-11ed-8018-cb2fec1cb755');
        });
    }

    /**
     * Change the language
     * @param language language
     */
    changeLanguage(language) {
        this.selectedLanguage = language;
        this.translate.use(this.selectedLanguage);
        this.shareDataService.shareLang(language);
        localStorage.setItem('language', language);
    }

    /**
     * Toggles the right sidebar
     */
    toggleRightSidebar() {
        this.settingsButtonClicked.emit();
    }

    /**
     * Toggle the menu bar when having mobile screen
     */
    toggleMobileMenu(event: any) {
        event.preventDefault();
        this.mobileMenuButtonClicked.emit();
    }

    /**
     * Logout the user
     */
    logout() {
        this.authService.logout();
        this.router.navigate(['/account/company']);
    }

    /**
     * Fetches the notification
     * Note: For now returns the hard coded notifications
     */
    _fetchNotifications() {
        this.notificationItems = [{
            text: 'Caleb Flakelar commented on Admin',
            subText: '1 min ago',
            icon: 'mdi mdi-comment-account-outline',
            bgColor: 'primary',
            redirectTo: '/notification/1'
        },
            {
                text: 'New user registered.',
                subText: '5 min ago',
                icon: 'mdi mdi-account-plus',
                bgColor: 'info',
                redirectTo: '/notification/2'
            },
            {
                text: 'Cristina Pride',
                subText: 'Hi, How are you? What about our next meeting',
                icon: 'mdi mdi-comment-account-outline',
                bgColor: 'success',
                redirectTo: '/notification/3'
            },
            {
                text: 'Caleb Flakelar commented on Admin',
                subText: '2 days ago',
                icon: 'mdi mdi-comment-account-outline',
                bgColor: 'danger',
                redirectTo: '/notification/4'
            },
            {
                text: 'Caleb Flakelar commented on Admin',
                subText: '1 min ago',
                icon: 'mdi mdi-comment-account-outline',
                bgColor: 'primary',
                redirectTo: '/notification/5'
            },
            {
                text: 'New user registered.',
                subText: '5 min ago',
                icon: 'mdi mdi-account-plus',
                bgColor: 'info',
                redirectTo: '/notification/6'
            },
            {
                text: 'Cristina Pride',
                subText: 'Hi, How are you? What about our next meeting',
                icon: 'mdi mdi-comment-account-outline',
                bgColor: 'success',
                redirectTo: '/notification/7'
            },
            {
                text: 'Caleb Flakelar commented on Admin',
                subText: '2 days ago',
                icon: 'mdi mdi-comment-account-outline',
                bgColor: 'danger',
                redirectTo: '/notification/8'
            }];
    }
}
