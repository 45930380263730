import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../../models/auth.models';
import { BaseApiService } from '../../helpers/base-api.service';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
    constructor(private http: HttpClient, private baseApiService: BaseApiService) { }

    getAll() {
        return this.http.get<User[]>(`/api/login`);
    }

    registerUse(payload: any) {
        return this.baseApiService.postJson('/api/portal/register/insert', payload);
    }
}